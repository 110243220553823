import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL, MAX_COLS } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Chip,
  Divider,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Box,
  createFilterOptions,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import CButton from "../../../utils/components/Button";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import EditWrap from "../../commonComponent/editWrap";
const filter = createFilterOptions();

const extraPrecisionSplitter = " + ";
export const UNCERTAINTY_LINK = "__";
export const EXTRA_COLUMN_LINK = "__";

export function EditTable(props) {
  const [rangeCol, setRangeCol] = useState("");
  const [datasheetColumnName, setDatasheetColumnName] = useState([]);
  const [datasheetConfiguration, setDatasheetConfiguration] = useState({
    values: {},
    formulas: {},
    totalColumns: 0,
    hasCommonHeader: false,
    commonHeaderConfig: null,
    referenceImageUpload: "",
    uncertaintyReferenceType: 0,
    budget: {
      formulas: {},
    },
    printConfig: {
      datasheetColumnHide: "",
      printCommonHeaderConfig: null,
    },
  });
  const [certificateColumnName, setCertificateColumnName] = useState([]);
  const [certificateConfiguration, setCertificateConfiguration] = useState({
    relations: {},
    totalColumns: 0,
    hasCommonHeader: false,
    commonHeaderConfig: null,
  });
  const [condConfiguration, setCondConfiguration] = useState({
    relations: {},
  });
  const [customPrecision, setCustomPrecision] = useState({});
  const [typeBConfiguration, setTypeBConfiguration] = useState({
    relations: {},
  });

  const [selectedBudgetFormula, setSelectedBudgetFormula] = useState(null);
  const [selectedValueCol, setSelectedValueCol] = useState(null);
  const [selectedFormulaCol, setSelectedFormulaCol] = useState(null);
  const [selectedCondition, setSelectedCondition] = useState("default");
  const [selectedFormulaCol1, setSelectedFormulaCol1] = useState(null);
  const [dsCol, setDsCol] = useState(null);
  const [dsCol2, setDsCol2] = useState(null);
  const [plusPrecision, setPlusPrecision] = useState("");
  const [stdRangePref, setStdRangePref] = useState("null");
  const [conditionsCol, setConditionsCol] = useState(null);

  const [selectedCertFormulaCol, setSelectedCertFormulaCol] = useState(null);
  const [selectedDatasheetRelCol, setSelectedDatasheetRelCol] = useState(null);
  const [selectedCertRelCol, setSelectedCertRelCol] = useState(null);
  const [selectedTypeBRelCol, setSelectedTypeBRelCol] = useState(null);

  var [instrumentArray, setInstrumentArray] = useState([]);
  var [uncertaintyFactors, setUncertaintyFactors] = useState([]);
  const [unitType, setUnitType] = useState("");
  const [parameterName, setParameterName] = useState("");
  const [isUncertainty, setUncertainty] = useState(false);
  const [isUncertaintyCertificate, setUncertaintyCertificate] = useState(false);
  const [isVerticalUncertainty, setIsVerticalUncertainty] = useState(false);
  const [isCmc, setIsCmc] = useState(false);
  const [sketchPickerColor, setSketchPickerColor] = useState("#D12929");
  const [certificateTableStatus, setCertificateTableStatus] = useState(1);
  const [isComplianceStatus, setIsComplianceStatus] = useState({
    datasheet: 0,
    certificate: 0,
  });
  const [isGauge, setGauge] = useState(false);
  const [stdFilteration, setStdFilteration] = useState(false);

  const [tableName, setTableName] = useState("");
  const [instrumentId, setInstrumentId] = useState(null);
  const [instrumentName, setInstrumentName] = useState(null);
  const [defaultConfiguration, setDefaultConfiguration] = useState("");
  const [showHeader, setShowHeader] = useState(true);
  const [tableInstruction, setTableInstruction] = useState("");
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const [settingList, setsettingList] = useState({
    referenceImageUploadDatasheet: false,
    complianceStatus: false,
  });
  // destructuring rgba from state
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const params = useParams();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const onSubmit = () => {
    function formate(obj) {
      const formattedObj = JSON.parse(JSON.stringify(obj));
      function removeEmptyStringsAndObjects(obj) {
        for (let key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            removeEmptyStringsAndObjects(obj[key]);
            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          } else if (obj[key] === "") {
            delete obj[key];
          }
        }
      }
      // Remove empty strings and objects in "values"
      removeEmptyStringsAndObjects(formattedObj.values);
      // Remove empty strings and objects in "formulas"
      removeEmptyStringsAndObjects(formattedObj.formulas);
      // Remove empty strings in "readingGroupByConfig"
      if (formattedObj?.readingGroupByConfig === "") {
        delete formattedObj?.readingGroupByConfig;
      }
      return formattedObj;
    }
    const removeEmptyString = formate(datasheetConfiguration);

    var jsonData = {
      tableName: tableName,
      tableInstructions: tableInstruction,
      unitType: unitType,
      instrumentId: instrumentId,
      parameterName: parameterName,
      defaultConfiguration: JSON.stringify({
        ...removeEmptyString,
        typeb: typeBConfiguration,
        rangeCol: rangeCol,
        isGauge: isGauge,
        stdFilteration: stdFilteration,
      }),
      conditionFormatting: JSON.stringify({
        ...condConfiguration,
      }),
      customPrecision: JSON.stringify({
        ...customPrecision,
      }),
      isUncertainty: isUncertainty,
      isVerticalUncertainty: isVerticalUncertainty,
      isComplianceStatus: isComplianceStatus.datasheet,
    };
    for (let i = 1; i < MAX_COLS + 1; i++) jsonData["c" + i] = null;
    datasheetColumnName.forEach((column, index) => {
      jsonData["c" + (index + 1)] = column;
    });

    let url = BASE_URL;
    if (params.id > 0) {
      axiosWithToken
        .patch(url + `datasheetStaticTables/${params.id}`, jsonData)
        .then((res) => {
          submitCertificate();
        })
        .catch((error) => {
          console.log(error);
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        });
    } else {
      axiosWithToken
        .post(url + "datasheetStaticTables", jsonData)
        .then((res) => {
          submitCertificate();
        })
        .catch((error) => {
          console.log(error);
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        });
    }
  };

  const submitCertificate = () => {
    var jsonData = {
      tableName: tableName,
      unitType: unitType,
      instrumentId: instrumentId,
      defaultConfiguration: JSON.stringify({
        ...certificateConfiguration,
        showHeader,
      }),
      isUncertainty: isUncertaintyCertificate,
      isVerticalUncertainty: isVerticalUncertainty,
      isCmc: isCmc,
      status: certificateTableStatus,
      isComplianceStatus: isComplianceStatus.certificate,
    };
    for (let i = 1; i < MAX_COLS + 1; i++) jsonData["c" + i] = null;
    certificateColumnName.forEach((column, index) => {
      jsonData["c" + (index + 1)] = column;
    });

    if (params.id > 0) {
      axiosWithToken
        .patch(BASE_URL + `certificateStaticTables/${params.id}`, jsonData)
        .then((res) => {
          // props.setLoader(false);
          toast("Table updated!");
          window.location.reload();
        })
        .catch((error) => {
          // props.setLoader(false);
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong with certificate table!");
          }
        });
    } else {
      axiosWithToken
        .post(BASE_URL + `certificateStaticTables`, jsonData)
        .then((res) => {
          // props.setLoader(false);
          toast("new table added!");
          window.location.reload();
        })
        .catch((error) => {
          // props.setLoader(false);
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong in certificate!");
          }
        });
    }
  };

  const addDatasheetColumn = () => {
    setDatasheetColumnName([...datasheetColumnName, ""]);
    updateDatasheetConfiguration(
      "totalColumns",
      null,
      Number(datasheetConfiguration["totalColumns"]) + 1
    );
  };

  const deleteDatasheetColumn = (index) => {
    let newDatasheetColumnName = [...datasheetColumnName];
    newDatasheetColumnName.splice(index, 1);
    setDatasheetColumnName([...newDatasheetColumnName]);
  };
  const updateDatasheetColumnName = (value, index) => {
    const newColumnName = [...datasheetColumnName];
    newColumnName[index] = value;
    setDatasheetColumnName(newColumnName);
  };

  const updateDatasheetConfiguration = (type, column, value) => {
    let _datasheetConfiguration = { ...datasheetConfiguration };
    if (type === "formulas") {
      if (
        Object.keys(_datasheetConfiguration[type][column] || {}).length == 0
      ) {
        _datasheetConfiguration[type][column] = {};
      }
      _datasheetConfiguration[type][column][selectedCondition || "default"] =
        value;
    } else if (type === "budget.formulas") {
      if (Object.keys(_datasheetConfiguration["budget"] || {}).length == 0) {
        _datasheetConfiguration["budget"] = {
          formulas: {},
        };
      }
      if (
        Object.keys(_datasheetConfiguration["budget"]["formulas"][column] || {})
          .length == 0
      ) {
        _datasheetConfiguration["budget"]["formulas"][column] = {};
      }
      _datasheetConfiguration["budget"]["formulas"][column][
        selectedCondition || "default"
      ] = value;
    } else if (type === "printConfig.printCommonHeaderConfig") {
      _datasheetConfiguration["printConfig"].printCommonHeaderConfig = value;
    } else if (column != null) {
      _datasheetConfiguration[type][column] = value;
    } else {
      _datasheetConfiguration[type] = value;
    }

    setDatasheetConfiguration(_datasheetConfiguration);
  };

  const updateCertificateConfiguration = (
    type,
    column = null,
    value = null
  ) => {
    let _certificateConfiguration = { ...certificateConfiguration };
    if (column != null) {
      _certificateConfiguration[type][column] = value;
    } else if (
      type === "relations" &&
      selectedCertRelCol &&
      selectedDatasheetRelCol
    ) {
      _certificateConfiguration[type][selectedCertRelCol] =
        selectedDatasheetRelCol;
    } else {
      _certificateConfiguration[type] = value;
    }
    setCertificateConfiguration(_certificateConfiguration);
  };

  const condConfObj = {
    gt: "Greater than",
    lt: "Less than",
    between: "Between",
    precisionCountNotEqual: "Precision not equals",
    NAN: "Not A Number",
    Notbetween: "Not A Between",
    "Greater than": 1,
    "Less than": 2,
    Between: 3,
    "Precision not equals": 4,
    "Not A Number": 5,
    "Not A Between": 6,
    1: "gt",
    2: "lt",
    3: "between",
    4: "precisionCountNotEqual",
    5: "NAN",
    6: "Notbetween",
  };

  const updateConditionConfiguration = (type, column = null, value = null) => {
    let _conditionsConfiguration = { ...condConfiguration };
    if (column != null) {
      // Check if the column exists and is an array
      if (Array.isArray(_conditionsConfiguration[type][column])) {
        // Push the new value into the existing array
        _conditionsConfiguration[type][column].push(value);
      } else {
        // If it's not an array, initialize it as one with the new value
        _conditionsConfiguration[type][column] = [value];
      }
    } else if (type === "relations" && conditionsCol && selectedFormulaCol1) {
      let condition = condConfObj[conditionsCol];
      const newCondition = {
        condition: condition,
        dsCol: dsCol + "." + stdRangePref,
        color: sketchPickerColor,
      };
      // Ensure the column exists and is an array, then add the new condition
      if (_conditionsConfiguration[type][selectedFormulaCol1]) {
        if (
          Array.isArray(_conditionsConfiguration[type][selectedFormulaCol1])
        ) {
          _conditionsConfiguration[type][selectedFormulaCol1].push(
            newCondition
          );
        } else {
          _conditionsConfiguration[type][selectedFormulaCol1] = [newCondition];
        }
      } else {
        _conditionsConfiguration[type][selectedFormulaCol1] = [newCondition];
      }
    } else {
      _conditionsConfiguration[type] = value;
    }
    // Remove empty arrays from conditionsConfiguration
    Object.keys(_conditionsConfiguration[type]).forEach((key) => {
      if (
        Array.isArray(_conditionsConfiguration[type][key]) &&
        _conditionsConfiguration[type][key].length === 0
      ) {
        delete _conditionsConfiguration[type][key];
      }
    });
    setCondConfiguration(_conditionsConfiguration);
  };

  const updateCustomPrecision = (column = null, value = null) => {
    let _customPrecision = { ...customPrecision };
    _customPrecision[selectedFormulaCol1] =
      dsCol2 + extraPrecisionSplitter + plusPrecision + "." + stdRangePref;
    setCustomPrecision(_customPrecision);
  };

  const updateTypeBConfiguration = (type, column = null, value = null) => {
    let _typeBConfiguration = { ...typeBConfiguration };
    if (
      type === "relations" &&
      selectedTypeBRelCol &&
      selectedDatasheetRelCol
    ) {
      if (!_typeBConfiguration[type]) {
        _typeBConfiguration[type] = {};
      }

      _typeBConfiguration[type][selectedDatasheetRelCol] = selectedTypeBRelCol;
    }
    setTypeBConfiguration(_typeBConfiguration);
  };

  const addCertificateColumn = () => {
    setCertificateColumnName([...certificateColumnName, ""]);
    updateCertificateConfiguration(
      "totalColumns",
      null,
      Number(certificateConfiguration["totalColumns"]) + 1
    );
  };

  const deleteCertificateColumn = (index) => {
    let newCertificateColumnName = [...certificateColumnName];
    newCertificateColumnName.splice(index, 1);
    setCertificateColumnName([...newCertificateColumnName]);
  };

  const updateCertificateColumnName = (value, index) => {
    const newColumnName = [...certificateColumnName];
    newColumnName[index] = value;
    setCertificateColumnName(newColumnName);
  };

  const getDatasheet = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `datasheetStaticTables/${params.id}`)
      .then((res) => {
        setUnitType(res.data[0].unitType);
        setTableName(res.data[0].tableName);
        setTableInstruction(res.data[0].tableInstructions);
        setParameterName(res.data[0].parameterName);
        setUncertainty(res.data[0].isUncertainty);
        setGauge(
          res.data[0].defaultConfiguration
            ? JSON.parse(res.data[0].defaultConfiguration)?.isGauge
            : false
        );
        setStdFilteration(
          res.data[0].defaultConfiguration
            ? JSON.parse(res.data[0].defaultConfiguration)?.stdFilteration
            : false
        );
        setIsComplianceStatus((prevState) => ({
          ...prevState,
          datasheet: res.data[0].isComplianceStatus,
        }));
        setDatasheetConfiguration(
          res.data[0].defaultConfiguration != null
            ? JSON.parse(res.data[0].defaultConfiguration)
            : {}
        );
        setTypeBConfiguration(
          res.data[0].defaultConfiguration != null
            ? JSON.parse(res.data[0].defaultConfiguration).typeb
              ? JSON.parse(res.data[0].defaultConfiguration).typeb
              : {}
            : {}
        );
        const conditionFormatting =
          res.data[0].conditionFormatting != null
            ? JSON.parse(res.data[0].conditionFormatting)
            : {};
        const formattedConditions = Object.entries(
          conditionFormatting.relations || {}
        ).reduce((acc, [key, value]) => {
          acc[key] = Array.isArray(value) ? value : [value];
          return acc;
        }, {});
        setCondConfiguration({ relations: formattedConditions });
        setCustomPrecision(
          res.data[0].customPrecision != null
            ? JSON.parse(res.data[0].customPrecision)
            : {}
        );
        const columnArr = [];
        for (let i = 1; i < MAX_COLS + 1; i++) {
          columnArr.push(res.data[0]?.["c" + i]);
        }

        setDatasheetColumnName(
          columnArr.filter(function (el) {
            return el != null;
          })
        );
        let rangeCol = JSON.parse(res.data[0].defaultConfiguration).rangeCol;
        setRangeCol(rangeCol);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const getCertificate = () => {
    let url = BASE_URL;
    let certificateReadingColumnNames = [];
    for (let i = 1; i < MAX_COLS + 1; i++) {
      certificateReadingColumnNames.push(`cert.c${i}`);
    }
    certificateReadingColumnNames = certificateReadingColumnNames.join(",");

    axiosWithToken
      .get(
        url +
          `xjoin?_join=cert.certificateStaticTables,_j,inst.instruments&_on1=(cert.instrumentId,eq,inst.id)&_fields=cert.status,cert.instrumentId,cert.isUncertainty,cert.isComplianceStatus,cert.isVerticalUncertainty,cert.isCmc,cert.defaultConfiguration,${certificateReadingColumnNames},inst.instrumentName&_where=(cert.id,eq,${params.id})`
      )
      // /api/
      .then((res) => {
        setCertificateTableStatus(res.data[0].cert_status);
        setInstrumentId(res.data[0].cert_instrumentId);
        setInstrumentName(res.data[0].inst_instrumentName);
        setUncertaintyCertificate(res.data[0].cert_isUncertainty);
        setIsVerticalUncertainty(res.data[0].cert_isVerticalUncertainty);
        setIsCmc(res.data[0].cert_isCmc);
        setIsComplianceStatus((prevState) => ({
          ...prevState,
          certificate: res.data[0].cert_isComplianceStatus,
        }));
        setCertificateConfiguration(
          res.data[0].cert_defaultConfiguration != null
            ? JSON.parse(res.data[0].cert_defaultConfiguration)
            : {}
        );
        setShowHeader(
          res.data[0].cert_defaultConfiguration != null
            ? JSON.parse(res.data[0].cert_defaultConfiguration)?.showHeader
            : true
        );
        setDefaultConfiguration(res.data[0].cert_defaultConfiguration);
        const columnArr = [];

        for (let i = 1; i < MAX_COLS + 1; i++) {
          columnArr.push(res.data[0]?.["cert_c" + i]);
        }

        setCertificateColumnName(
          columnArr.filter(function (el) {
            return el != null;
          })
        );
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const fetchUncertaintyFactors = () => {
    let filteredInstrument = instrumentArray?.find(
      (instrument) => instrument.id === instrumentId
    );
    if (!filteredInstrument) return;
    const { uncertaintyFactors, commonUncertaintyFactors } = filteredInstrument;

    const uncertaintyIds = [
      ...(uncertaintyFactors ? uncertaintyFactors.split(",") : []),
      ...(commonUncertaintyFactors ? commonUncertaintyFactors.split(",") : []),
    ];
    if (!uncertaintyIds) return;
    axiosWithToken
      .get(BASE_URL + `uncertainty?_where=(id,in,${uncertaintyIds.join(",")})`)
      .then((res) => {
        setUncertaintyFactors([...res.data]);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Failed to uncertainty factors!");
        }
      });
  };

  const setMappedRange = (val) => {
    console.log("==>>", val);
    setRangeCol(val);
  };

  function fetchSettings() {
    axiosWithToken
      .get(BASE_URL + `settings`)
      .then((res) => {
        let referenceImageUpload = false,
          complianceStatus = false;
        res?.data?.forEach((setting) => {
          if (
            setting.keyName === "tableTemplateReferenceimgDatasheet" &&
            setting.value.toLowerCase() === "true"
          ) {
            referenceImageUpload = true;
          } else if (
            setting.keyName === "tableTemplateComplianceStatus" &&
            setting.value.toLowerCase() === "true"
          ) {
            complianceStatus = true;
          }
        });
        setsettingList({
          referenceImageUploadDatasheet: referenceImageUpload,
          complianceStatus: complianceStatus,
        });
      })
      .catch((err) => console.error("Error fetching setting:", err));
  }

  useEffect(() => {
    fetchSettings();
    getInstrumentsList();
    if (params.id > 0) {
      getDatasheet();
      getCertificate();
    }
  }, []);

  useEffect(() => {
    // if (params.id > 0) {
    fetchUncertaintyFactors();
    // }
  }, [instrumentArray, instrumentId]);

  const renderHelpModal = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Formula Help
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            same as mathematical expression with following variations
            <hr />
            <ul>
              <li>
                1. multiplication: <code>*</code>
              </li>
              <li>
                2. for power:{" "}
                <code>pow(&lt;base value&gt;, &lt;exponent&gt;)</code>
              </li>
              <li>
                3. for square root : <code>sqrt(&lt;value&gt;)</code>, value:
                either number of column code (eg. c1)
              </li>
            </ul>
          </Typography>
        </Box>
      </Modal>
    );
  };

  return (
    <EditWrap>
      <Typography variant="h6" component="h6" sx={{ mb: 2, textAlign: "left" }}>
        {params.id > 0 ? "Update" : "Create new"} static table
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={instrumentId + ", " + instrumentName}
            options={instrumentArray.map((instrument) => {
              return {
                label: instrument.id + ", " + instrument.instrumentName,
              };
            })}
            onChange={(event, value) => {
              if (value) {
                setInstrumentId(parseInt(value.label.split(",")[0]));
                setInstrumentName(value.label.split(",")[1]);
                // fetchUncertaintyFactors(value.label.split(",")[0]);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Instrument" />
            )}
            disabled={Number(params.id) > 0}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="New table name"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            value={tableName}
            onChange={(e) => setTableName(e.target.value)}
          />
        </Grid>
      </Grid>

      {/* datasheet table confiurations */}
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Datasheet table configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>

        <div sx={{ mb: 3 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            1. add columns
            <CButton
              variant="contained"
              size="small"
              label={<AddIcon />}
              sx={{ ml: 3 }}
              onClick={() => {
                addDatasheetColumn();
              }}
            ></CButton>
            {`    use "${UNCERTAINTY_LINK}" to show uncertainty link e.g. col__typeA1`}
          </Typography>
          <Grid container spacing={1}>
            {datasheetColumnName.map((column, index) => (
              <Grid item xs={4}>
                <OutlinedInput
                  id="outlined-adornment-password"
                  label={"column " + (index + 1) + "(c" + (index + 1) + ")"}
                  value={column}
                  placeholder={
                    "column " + (index + 1) + " (c" + +(index + 1) + ")"
                  }
                  onChange={(e) => {
                    updateDatasheetColumnName(e.target.value, index);
                  }}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          deleteDatasheetColumn(index);
                        }}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox />}
                label="Show Column Header in Certificate"
                checked={showHeader}
                onChange={(e) => {
                  setShowHeader(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <br />
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            2. Define common header{" "}
            <CButton
              variant="text"
              size="small"
              label={<HelpCenterIcon />}
              onClick={handleOpen}
            ></CButton>
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox />}
                label="Has common header"
                checked={datasheetConfiguration?.hasCommonHeader}
                onChange={(e) => {
                  updateDatasheetConfiguration(
                    "hasCommonHeader",
                    null,
                    e.target.checked
                  );
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Define common header"
                placeholder="Define common header (, for column separation, $: for empty column)"
                size="small"
                fullWidth
                variant="outlined"
                inputProps={{
                  autoComplete: "off",
                }}
                value={datasheetConfiguration?.commonHeaderConfig || ""}
                onChange={(e) =>
                  updateDatasheetConfiguration(
                    "commonHeaderConfig",
                    null,
                    e.target.value
                  )
                }
              />
            </Grid>
          </Grid>
        </div>
        <br />
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          3. Set default values (optional)
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedValueCol}
                label="Select column"
                onChange={(e) => {
                  setSelectedValueCol(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="outlined-basic"
              label="Add values ( comma separated )"
              size="small"
              fullWidth
              variant="outlined"
              inputProps={{
                autoComplete: "off",
              }}
              value={datasheetConfiguration?.values[selectedValueCol] || ""}
              onChange={(e) =>
                updateDatasheetConfiguration(
                  "values",
                  selectedValueCol,
                  e.target.value
                )
              }
            />
          </Grid>
        </Grid>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          4. Map Column to Range
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rangeCol}
                label="Select column"
                onChange={(e) => {
                  setMappedRange(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          5. Set formula (optional){" "}
          <CButton
            variant="text"
            size="small"
            label={<HelpCenterIcon />}
            onClick={handleOpen}
          ></CButton>
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            {`    use "${EXTRA_COLUMN_LINK}" to show link to extra column link e.g. col__extra`}
          </Typography>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedFormulaCol}
                label="Select column"
                onChange={(e) => {
                  setSelectedFormulaCol(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              value={selectedCondition}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setSelectedCondition(newValue);
                } else if (newValue && newValue.inputValue) {
                  setSelectedCondition(newValue.inputValue);
                } else {
                  setSelectedCondition(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={Object.keys(
                datasheetConfiguration?.formulas[selectedFormulaCol] || {
                  default: "",
                }
              )}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
              }}
              renderOption={(props, option) => (
                <li {...props} style={{ display: "flex" }}>
                  <div style={{ flex: "1" }}>{option}</div>
                  <button
                    style={{ color: "#888" }}
                    onClick={(e) => {
                      let tmpDt = { ...datasheetConfiguration };
                      let tmp = tmpDt?.formulas[selectedFormulaCol];
                      delete tmp[props["key"]];
                      if (Object.keys(tmp).length === 0)
                        delete tmpDt.formulas[selectedFormulaCol];
                      else tmpDt.formulas[selectedFormulaCol] = tmp;
                      setDatasheetConfiguration(tmpDt);
                      setSelectedCondition("");
                    }}
                  >
                    <svg
                      className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-ptiqhd-MuiSvgIcon-root"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="CloseIcon"
                    >
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                  </button>
                </li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Set condition" />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              label="Add formula"
              size="small"
              fullWidth
              variant="outlined"
              inputProps={{
                autoComplete: "off",
              }}
              value={
                datasheetConfiguration?.formulas?.[selectedFormulaCol]?.[
                  selectedCondition
                ] || ""
              }
              onChange={(e) =>
                updateDatasheetConfiguration(
                  "formulas",
                  selectedFormulaCol,
                  e.target.value
                )
              }
            />
            <div style={{ display: "flex", marginTop: "5px" }}>
              <Chip
                variant="outlined"
                label="range higher"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol]?.[
                      selectedCondition
                    ] || "") + " range_high "
                  );
                }}
              />
              <Chip
                variant="outlined"
                label="range lower"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol]?.[
                      selectedCondition
                    ] || "") + " range_low "
                  );
                }}
              />
              <Chip
                variant="outlined"
                label="LC"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol]?.[
                      selectedCondition
                    ] || "") + " lc "
                  );
                }}
              />
              <Chip
                variant="outlined"
                label="accuracy"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol]?.[
                      selectedCondition
                    ] || "") + " accuracy "
                  );
                }}
              />
            </div>
          </Grid>
        </Grid>

        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            6. Conditional Formatting
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Datasheet Column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFormulaCol1}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedFormulaCol1(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value={"uncertainty"}>{"Uncertainty"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={conditionsCol}
                  label="Select condition"
                  onChange={(e) => {
                    console.log(e.target.value);
                    // setSelectedFormulaCol(e.target.value);
                    setConditionsCol(e.target.value);
                  }}
                  size="small"
                >
                  {[
                    "Greater than",
                    "Less than",
                    "Between",
                    "Precision not equals",
                    "Not A Number",
                    "Not A Between",
                  ].map((columnName, index) => {
                    return <MenuItem value={index + 1}>{columnName}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Columns
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dsCol}
                  label="Select column"
                  onChange={(e) => {
                    setDsCol(e.target.value);
                    // setSelectedFormulaCol(e.target.value);
                  }}
                  size="small"
                >
                  {[
                    "",
                    "DUC Range",
                    "DUC LC",
                    "Master Range LC",
                    "accuracy",
                    "Master LC",
                    ...datasheetColumnName,
                  ].map((columnName, index) => {
                    // preapre items above datasheet cols
                    let aboveDsItems = 6;
                    if (index < aboveDsItems)
                      return (
                        <MenuItem value={index + 1}>{columnName}</MenuItem>
                      );
                    else
                      return (
                        <MenuItem value={"ds" + (index - aboveDsItems + 1)}>
                          Datasheet: {columnName}
                        </MenuItem>
                      );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {dsCol == 4 ? (
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Std. Pref.
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stdRangePref}
                    label="Select column"
                    onChange={(e) => {
                      setStdRangePref(e.target.value);
                      // setSelectedFormulaCol(e.target.value);
                    }}
                    size="small"
                  >
                    {["NA", "1", "2", "3", "4"].map((columnName, index) => {
                      return <MenuItem value={index}>{columnName}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={1}>
              <input
                type="color"
                value={sketchPickerColor}
                style={{
                  backgroundColor: `${sketchPickerColor}`,
                  width: 70,
                  height: 40,
                  border: "1px solid white",
                  borderRadius: "20px",
                }}
                onChange={(e) => {
                  setSketchPickerColor(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateConditionConfiguration("relations")}
              ></CButton>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={4} align="left">
              <h6>relations:</h6>
              <table style={{ textAlign: "center" }}>
                <tr>
                  <th>
                    <div style={{ width: "150px" }}>Datasheet Column</div>
                  </th>
                  <th>
                    <div style={{ width: "200px" }}>Condition</div>
                  </th>
                  <th>
                    <div style={{ width: "200px" }}>Target Column</div>
                  </th>
                  <th>
                    <div style={{ width: "100px" }}>Color</div>
                  </th>
                  <th>
                    <div style={{ width: "100px" }}>Action</div>
                  </th>
                </tr>
                {Object.keys(condConfiguration.relations).map((dC) => {
                  const conditions = condConfiguration.relations[dC];
                  return Array.isArray(conditions) ? (
                    conditions.map((condition, index) => (
                      <tr key={`${dC}-${index}`}>
                        <td>
                          {dC.startsWith("c")
                            ? datasheetColumnName[Number(dC.split("c")[1]) - 1]
                            : dC}
                        </td>
                        <td>
                          {condConfObj[condition.condition] ||
                            condition.condition}
                        </td>
                        <td>
                          {/* Logic for displaying the target column */}
                          {`${
                            [
                              "range higher",
                              "Out Off Range",
                              "DUC LC",
                              "Master Range LC",
                              "accuracy",
                              "Master LC",
                              ...datasheetColumnName,
                            ][
                              Number(
                                condition.dsCol.includes("ds")
                                  ? condition.dsCol.split(".")[0].split("ds")[1]
                                  : condition.dsCol.split(".")[0]
                              ) -
                                1 +
                                (condition.dsCol.includes("ds") ? 6 : 0)
                            ]
                          }${
                            condition.dsCol.split(".")[0] === "4"
                              ? `.${
                                  condition.dsCol.split(".")[1]
                                    ? Number(condition.dsCol.split(".")[1])
                                    : condition.dsCol.split(".")[1]
                                }`
                              : ""
                          }`}
                        </td>
                        <td>
                          <input
                            type="color"
                            value={condition.color}
                            style={{
                              backgroundColor: `${condition.color}`,
                              width: 70,
                              height: 40,
                              border: "1px solid white",
                              borderRadius: "20px",
                            }}
                            disabled
                          />
                        </td>
                        <td>
                          <Button
                            size="small"
                            onClick={() => {
                              const tmpC = { ...condConfiguration };
                              const tmp = { ...tmpC.relations };

                              // Remove the specific condition
                              tmp[dC] = tmp[dC].filter(
                                (_, idx) => idx !== index
                              );

                              // Check if the array is now empty, then delete the key
                              if (tmp[dC].length === 0) {
                                delete tmp[dC]; // Remove the column if empty
                              }

                              tmpC.relations = { ...tmp };
                              setCondConfiguration(tmpC);
                            }}
                          >
                            <DeleteIcon style={{ color: "#8d7a7c" }} />
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr key={dC}>
                      <td>
                        {datasheetColumnName[Number(dC.split("c")[1]) - 1]}
                      </td>
                      <td>
                        {condConfObj[conditions.condition] ||
                          conditions.condition}
                      </td>
                      <td>{conditions.dsCol}</td>
                      <td>{conditions.color}</td>
                      <td>
                        <Button
                          size="small"
                          onClick={() => {
                            const tmpC = { ...condConfiguration };
                            delete tmpC.relations[dC]; // Remove the condition
                            setCondConfiguration(tmpC);
                          }}
                        >
                          <DeleteIcon style={{ color: "#8d7a7c" }} />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </Grid>
          </Grid>
        </div>

        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            7. Set Custom Precision
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Datasheet Column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFormulaCol1}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedFormulaCol1(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value={"uncertainty"}>{"Uncertainty"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Target Columns
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dsCol2}
                  label="Select column"
                  onChange={(e) => {
                    setDsCol2(e.target.value);
                    // setSelectedFormulaCol(e.target.value);
                  }}
                  size="small"
                >
                  {(() => {
                    let targetColMapping = {
                      1: "range higher",
                      2: "Out Off Range",
                      3: "DUC LC",
                      4: "Master Range: LC",
                      5: "accuracy",
                      6: "Greater LC",
                      7: "Actual Precision",
                      "standardRanges:cmv": "Master Range: CMV",
                    };

                    let menuItems = [];
                    menuItems = Object.keys(targetColMapping).map(
                      (key, index) => {
                        return (
                          <MenuItem value={key}>
                            {targetColMapping[key]}
                          </MenuItem>
                        );
                      }
                    );
                    datasheetColumnName.forEach((columnName, index) => {
                      menuItems.push(
                        <MenuItem value={"ds" + (index + 1)}>
                          datasheet: {columnName}
                        </MenuItem>
                      );
                    });
                    return menuItems;
                  })()}
                  <MenuItem value={"manual"}>Manual</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* {dsCol2 == 3 || dsCol2 == 4 ? ( */}
            <Grid item xs={1}>
              <TextField
                id="outlined-basic"
                label="+ precision"
                size="small"
                fullWidth
                variant="outlined"
                inputProps={{
                  autoComplete: "off",
                }}
                value={plusPrecision}
                onChange={(e) => setPlusPrecision(e.target.value || "")}
              />
            </Grid>
            {/* // ) : (
            //   ""
            // )} */}
            {dsCol2 == 4 ||
            dsCol2 == 6 ||
            dsCol2 == 8 ||
            String(dsCol2).includes("standardRanges") ||
            dsCol2 == "manual" ? (
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Std. Pref.
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stdRangePref}
                    label="Select column"
                    onChange={(e) => {
                      setStdRangePref(e.target.value);
                      // setSelectedFormulaCol(e.target.value);
                    }}
                    size="small"
                  >
                    {[
                      "NA",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                    ].map((columnName, index) => {
                      if (columnName === "NA")
                        return <MenuItem value={"null"}>{columnName}</MenuItem>;
                      else
                        return <MenuItem value={index}>{columnName}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateCustomPrecision()}
              ></CButton>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={4} align="left">
              <h6>Custom Precision mappings: </h6>
              <table style={{ textAlign: "center" }}>
                <tr>
                  <th>
                    <div style={{ width: "150px" }}>source Column</div>
                  </th>
                  <th>
                    <div style={{ width: "200px" }}>Target Column</div>
                  </th>
                  <th>
                    <div style={{ width: "100px" }}>Pref</div>
                  </th>
                  <th>
                    <div style={{ width: "100px" }}>Action</div>
                  </th>
                </tr>

                {Object.keys(customPrecision ? customPrecision : {}).map(
                  (dC) => (
                    <tr>
                      {[...[dC].concat(customPrecision[dC].split("."))]
                        .concat([""])
                        .map((col, idx) => {
                          if (idx === 0)
                            return (
                              <td>
                                {col !== "uncertainty"
                                  ? datasheetColumnName[
                                      Number(col.split("c")[1]) - 1
                                    ]
                                  : col}
                              </td>
                            );
                          else if (idx === 1) {
                            let lt0 = col?.split(extraPrecisionSplitter)[0],
                              lt1 =
                                lt0 == 2 || lt0 == 3
                                  ? col.includes("ds")
                                    ? col?.split(extraPrecisionSplitter)[1]
                                    : col?.split(extraPrecisionSplitter)[1]
                                  : "";
                            lt1 = lt1 ? extraPrecisionSplitter + lt1 : "";
                            return (
                              <td>
                                {/* {col === "manual"
                                  ? col
                                  : [
                                      "range higher",
                                      "Out Off Range",
                                      "DUC LC",
                                      "Master Range LC",
                                      "accuracy",
                                      "Greater LC",
                                      "Actual Precision",
                                      "Master Range CMV",
                                      ...datasheetColumnName,
                                    ][lt0] + lt1} {/* {col === "manual"
                                  ? col
                                  : [
                                      "range higher",
                                      "Out Off Range",
                                      "DUC LC",
                                      "Master Range LC",
                                      "accuracy",
                                      "Greater LC",
                                      "Actual Precision",
                                      "Master Range CMV",
                                      ...datasheetColumnName,
                                    ][lt0] + lt1} */}

                                {(() => {
                                  let columns = {
                                    manual: "manual",
                                    1: "range higher",
                                    2: "Out Off Range",
                                    3: "DUC LC",
                                    4: "Master Range: LC",
                                    5: "accuracy",
                                    6: "Greater LC",
                                    7: "Actual Precision",
                                    "standardRanges:cmv": "Master Range: CMV",
                                  };
                                  datasheetColumnName.forEach(
                                    (columnName, index) => {
                                      columns[
                                        `ds${index + 1}`
                                      ] = `datasheet: ${columnName}`;
                                    }
                                  );
                                  return columns[lt0] + lt1;
                                })()}
                              </td>
                            );
                          } else if (idx === 3)
                            return (
                              <td>
                                <Button size="sm">
                                  <DeleteIcon
                                    style={{
                                      color: "#8d7a7c",
                                    }}
                                    onClick={(e) => {
                                      let tmp = { ...customPrecision };
                                      delete tmp[dC];
                                      setCustomPrecision({ ...tmp });
                                    }}
                                  />
                                </Button>
                              </td>
                            );
                          return <td>{col}</td>;
                        })}
                    </tr>
                  )
                )}
              </table>
            </Grid>
          </Grid>
        </div>

        {settingList?.referenceImageUploadDatasheet && (
          <div>
            <Typography variant="h6" component="h6" style={{ float: "left" }}>
              8. Reference image upload option
            </Typography>
            <Grid container spacing={1} sx={{ mb: 3 }}>
              <Grid item xs={4}>
                <Autocomplete
                  id="outlined-basic"
                  multiple
                  options={datasheetColumnName || []}
                  size="small"
                  getOptionLabel={(option) => option || ""}
                  value={
                    (datasheetConfiguration?.referenceImageUpload
                      ? datasheetConfiguration.referenceImageUpload.split(",")
                      : []
                    ).map(
                      (entry) =>
                        datasheetColumnName[parseInt(entry.substring(1)) - 1]
                    ) || []
                  }
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reference image upload option"
                    />
                  )}
                  fullWidth
                  variant="outlined"
                  onChange={(_, value) => {
                    const mergedValues = value
                      .map(
                        (item) => `c${datasheetColumnName.indexOf(item) + 1}`
                      )
                      .join(",");
                    setDatasheetConfiguration((prevState) => ({
                      ...prevState,
                      referenceImageUpload: mergedValues,
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </div>
        )}

        <div style={{ width: "100%" }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            9. Table Instructions
          </Typography>
          <textarea
            style={{ width: "100%" }}
            value={tableInstruction}
            onChange={(e) => setTableInstruction(e.target.value)}
          />
        </div>

        <div style={{ width: "100%", marginTop: "5px" }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            10. Reading Group By Config
          </Typography>
          <textarea
            style={{ width: "100%" }}
            value={datasheetConfiguration?.readingGroupByConfig || ""}
            onChange={(e) => {
              setDatasheetConfiguration((prevState) => ({
                ...prevState,
                readingGroupByConfig: e.target.value,
              }));
            }}
          />
        </div>

        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            11. Print Datasheet Configuration (optional)
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={4}>
              <Autocomplete
                id="outlined-basic"
                multiple
                options={[...(datasheetColumnName || []), "uncertainty"]}
                size="small"
                getOptionLabel={(option) => option || ""}
                value={[
                  ...(datasheetConfiguration?.printConfig?.datasheetColumnHide
                    ? datasheetConfiguration?.printConfig?.datasheetColumnHide
                        .split(",")
                        .map((entry) => {
                          if (entry === "uncertainty") {
                            return "uncertainty";
                          }
                          return datasheetColumnName[
                            parseInt(entry.substring(1)) - 1
                          ];
                        })
                    : []),
                ]}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField {...params} label="Datasheet Column Hide" />
                )}
                fullWidth
                variant="outlined"
                onChange={(_, value) => {
                  const mergedValues = value
                    .map((item) => {
                      if (item === "uncertainty") {
                        return "uncertainty";
                      }
                      return `c${datasheetColumnName.indexOf(item) + 1}`;
                    })
                    .join(",");
                  setDatasheetConfiguration((prevState) => ({
                    ...prevState,
                    printConfig: {
                      ...prevState.printConfig,
                      datasheetColumnHide: mergedValues,
                    },
                  }));
                }}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Define print common header"
                placeholder="Define print common header (, for column separation, $: for empty column)"
                size="small"
                fullWidth
                variant="outlined"
                inputProps={{
                  autoComplete: "off",
                }}
                value={
                  datasheetConfiguration?.printConfig
                    ?.printCommonHeaderConfig || ""
                }
                onChange={(e) =>
                  updateDatasheetConfiguration(
                    "printConfig.printCommonHeaderConfig",
                    null,
                    e.target.value
                  )
                }
              />
            </Grid>
          </Grid>
        </div>

        <br />
        <div style={{ display: "flex" }}>
          <Grid item xs={3} style={{ alignItems: "start" }}>
            <FormControlLabel
              classes={{
                alignItems: "left",
              }}
              style={{ display: "flex" }}
              control={
                <Checkbox
                  checked={isUncertainty}
                  onChange={(e) => {
                    setUncertainty(e.target.checked);
                  }}
                />
              }
              label="Show Expanded Uncertainty"
            />
          </Grid>

          <Grid item xs={4} style={{ alignItems: "start" }}>
            <FormControlLabel
              classes={{
                alignItems: "left",
              }}
              style={{ display: "flex" }}
              control={
                <Checkbox
                  checked={certificateTableStatus === 0 ? true : false}
                  onChange={(e) => {
                    setCertificateTableStatus(e.target.checked == true ? 0 : 1);
                  }}
                />
              }
              label="Hide in certificate"
            />
          </Grid>

          {settingList?.complianceStatus && (
            <Grid item xs={4} style={{ alignItems: "start" }}>
              <FormControlLabel
                classes={{
                  alignItems: "left",
                }}
                style={{ display: "flex" }}
                control={
                  <Checkbox
                    checked={isComplianceStatus.datasheet == 1 ? true : false}
                    onChange={(e) => {
                      setIsComplianceStatus((prevState) => ({
                        ...prevState,
                        datasheet: e.target.checked ? 1 : 0,
                      }));
                    }}
                  />
                }
                label="Compliance Status"
              />
            </Grid>
          )}

          <Grid item xs={4} style={{ alignItems: "start" }}>
            <FormControlLabel
              classes={{
                alignItems: "left",
              }}
              style={{ display: "flex" }}
              control={
                <Checkbox
                  checked={isGauge}
                  onChange={(e) => {
                    setGauge(e.target.checked);
                  }}
                />
              }
              label="Gauge"
            />
          </Grid>

          <Grid item xs={4} style={{ alignItems: "start" }}>
            <FormControlLabel
              classes={{
                alignItems: "left",
              }}
              style={{ display: "flex" }}
              control={
                <Checkbox
                  checked={stdFilteration}
                  onChange={(e) => {
                    setStdFilteration(e.target.checked);
                  }}
                />
              }
              label="Standard Filteration"
            />
          </Grid>
        </div>
      </div>

      {/* certificate table confiurations */}
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Certificate table configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>
        <br />

        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          1. add columns
          <CButton
            variant="contained"
            size="small"
            label={<AddIcon />}
            sx={{ ml: 3 }}
            onClick={() => {
              addCertificateColumn();
            }}
          ></CButton>
        </Typography>

        <Grid container spacing={1} sx={{ mb: 3 }}>
          {certificateColumnName.map((column, index) => (
            <Grid item xs={4}>
              <OutlinedInput
                id="outlined-adornment-password"
                label={"column " + (index + 1)}
                value={column}
                placeholder={"column " + (index + 1)}
                onChange={(e) => {
                  updateCertificateColumnName(e.target.value, index);
                }}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        deleteCertificateColumn(index);
                      }}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          ))}
        </Grid>
        <br />
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            2. Define common header
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox />}
                label="Has common header"
                checked={certificateConfiguration?.hasCommonHeader}
                onChange={(e) => {
                  updateCertificateConfiguration(
                    "hasCommonHeader",
                    null,
                    e.target.checked
                  );
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Define common header"
                placeholder="Define common header (, for column separation, $: for empty column)"
                size="small"
                fullWidth
                variant="outlined"
                inputProps={{
                  autoComplete: "off",
                }}
                value={certificateConfiguration?.commonHeaderConfig || ""}
                onChange={(e) =>
                  updateCertificateConfiguration(
                    "commonHeaderConfig",
                    null,
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="text"
                size="small"
                label={<HelpCenterIcon />}
                onClick={handleOpen}
              ></CButton>
            </Grid>
          </Grid>
        </div>
        <br />
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            3. Set Relationship
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select datasheet column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDatasheetRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedDatasheetRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select certificate column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCertRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedCertRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {certificateColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateCertificateConfiguration("relations")}
              ></CButton>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={4} align="left">
              <table style={{ textAlign: "center" }}>
                <tr>
                  <th>
                    <div style={{ width: "200px" }}>Certificate Column</div>
                  </th>
                  <th>
                    <div style={{ width: "150px" }}>Datasheet Column</div>
                  </th>

                  <th>
                    <div style={{ width: "100px" }}>Action</div>
                  </th>
                </tr>
                {Object.keys(
                  certificateConfiguration["relations"]
                    ? certificateConfiguration["relations"]
                    : {}
                ).map((dC) => (
                  <tr>
                    {[
                      ...[dC].concat([
                        certificateConfiguration["relations"][dC],
                      ]),
                    ]
                      .concat([""])
                      .map((col, idx) => {
                        if (idx === 0)
                          return (
                            <td>
                              {
                                certificateColumnName[
                                  Number(col?.split("c")[1]) - 1
                                ]
                              }
                            </td>
                          );
                        else if (idx === 1) {
                          return (
                            <td>
                              {
                                datasheetColumnName[
                                  Number(col?.split("c")[1]) - 1
                                ]
                              }
                            </td>
                          );
                        } else if (idx === 2)
                          return (
                            <td>
                              <Button size="sm">
                                <DeleteIcon
                                  style={{
                                    color: "#8d7a7c",
                                  }}
                                  onClick={(e) => {
                                    let tmp = { ...certificateConfiguration };
                                    let tmp1 = {
                                      ...certificateConfiguration["relations"],
                                    };
                                    delete tmp1[dC];
                                    tmp["relations"] = { ...tmp1 };
                                    setCertificateConfiguration({ ...tmp });
                                  }}
                                />
                              </Button>
                            </td>
                          );
                        return <td>{col}</td>;
                      })}
                  </tr>
                ))}
              </table>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3} style={{ alignItems: "start" }}>
            <FormControlLabel
              classes={{
                alignItems: "left",
              }}
              style={{ display: "flex" }}
              control={
                <Checkbox
                  checked={isUncertaintyCertificate}
                  onChange={(e) => {
                    setUncertaintyCertificate(e.target.checked);
                  }}
                />
              }
              label="Show Expanded Uncertainty"
            />
          </Grid>

          {settingList?.complianceStatus && (
            <Grid item xs={3} style={{ alignItems: "start" }}>
              <FormControlLabel
                classes={{
                  alignItems: "left",
                }}
                style={{ display: "flex" }}
                control={
                  <Checkbox
                    checked={isComplianceStatus.certificate == 1 ? true : false}
                    onChange={(e) => {
                      setIsComplianceStatus((prevState) => ({
                        ...prevState,
                        certificate: e.target.checked ? 1 : 0,
                      }));
                    }}
                  />
                }
                label="Compliance Status"
              />
            </Grid>
          )}
        </Grid>
      </div>

      {/* type-b confiurations */}
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Uncertainty Report Configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            1. Type B - Set Relationship(optional)
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Datasheet column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDatasheetRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedDatasheetRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Type-B column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedTypeBRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedTypeBRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {uncertaintyFactors.map((uf, index) => {
                    return (
                      <MenuItem value={uf.id} key={uf.id}>
                        {uf.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateTypeBConfiguration("relations")}
              ></CButton>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={4} align="left">
              <table style={{ textAlign: "center" }}>
                <tr>
                  <th>
                    <div style={{ width: "150px" }}>Datasheet Column</div>
                  </th>
                  <th>
                    <div style={{ width: "200px" }}>Type-B Column</div>
                  </th>

                  <th>
                    <div style={{ width: "100px" }}>Action</div>
                  </th>
                </tr>
                {Object.keys(
                  typeBConfiguration["relations"]
                    ? typeBConfiguration["relations"]
                    : {}
                ).map((dC) => (
                  <tr>
                    {[...[dC].concat([typeBConfiguration["relations"][dC]])]
                      .concat([""])
                      .map((col, idx) => {
                        if (idx === 0)
                          return (
                            <td>
                              {
                                datasheetColumnName[
                                  Number(col?.split("c")[1]) - 1
                                ]
                              }
                            </td>
                          );
                        else if (idx === 1) {
                          return (
                            <td>
                              {
                                uncertaintyFactors?.filter(
                                  (u) => u.id == col
                                )[0]?.name
                              }
                            </td>
                          );
                        } else if (idx === 2)
                          return (
                            <td>
                              <Button size="sm">
                                <DeleteIcon
                                  style={{
                                    color: "#8d7a7c",
                                  }}
                                  onClick={(e) => {
                                    let tmp = { ...typeBConfiguration };
                                    let tmp1 = {
                                      ...typeBConfiguration["relations"],
                                    };
                                    delete tmp1[dC];
                                    tmp["relations"] = { ...tmp1 };
                                    setTypeBConfiguration({ ...tmp });
                                  }}
                                />
                              </Button>
                            </td>
                          );
                        return <td>{col}</td>;
                      })}
                  </tr>
                ))}
              </table>
            </Grid>
          </Grid>
          <div>
            <Typography
              variant="h6"
              component="h6"
              style={{ textAlign: "left" }}
            >
              2. Select Uncertainty From
            </Typography>
            <div style={{ display: "flex" }}>
              {["CMC", "Comparison of calculated and CMC values"]?.map(
                (option, index) => (
                  <Grid item xs={3} style={{ alignItems: "start" }} key={index}>
                    <FormControlLabel
                      classes={{
                        alignItems: "left",
                      }}
                      style={{ display: "flex" }}
                      control={
                        <Checkbox
                          checked={
                            datasheetConfiguration?.uncertaintyReferenceType ===
                            index + 1
                          }
                          onChange={(e) => {
                            setDatasheetConfiguration((prevState) => ({
                              ...prevState,
                              uncertaintyReferenceType:
                                prevState.uncertaintyReferenceType === index + 1
                                  ? 0
                                  : index + 1,
                            }));
                          }}
                        />
                      }
                      label={option}
                    />
                  </Grid>
                )
              )}
            </div>
          </div>
        </div>
        <br />
      </div>

      <hr />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <CButton
          variant="contained"
          label={params.id != 0 ? "Update" : "Save"}
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          onClick={() => {
            // props.setLoader(true);
            if (!tableName) {
              toast.error("tableName is required");
              return;
            }
            if (!instrumentId) {
              toast.error("Please select a instrument");
              return;
            }
            onSubmit();
          }}
        >
          SAVE
        </CButton>
      </Toolbar>
      <ToastContainer />
      {renderHelpModal()}
    </EditWrap>
  );
}
