
import {
  accuracyFormat,
  DateisValid,
  parseRange,
} from "../../../utils/components/accuracyandlcandrangesshow";

export let printCertificateSection = {
  firstpagelikeJRPM: false,
  lastpagelikeJRPM: true,
  dateofPerformancelikeJrpm: false,
  disciplineAndReadinTableStartNewpage: false,
  jrpmtable: false,
  readingTableSerialNoColumnShow: false,
};

export const headerConfig = {
  headerCALIBRATIONCERTIFICATE: true,
  headerCALIBRATIONCERTIFICATEgap: 10,
  headerCALIBRATIONCERTIFICATEwithoutgap: 7,
  lettterImgHeight: 120,
  headerTableHeight: 30,
  headerandbodygap: 10,
  tableRowfontSizes: 9,
  cellPadding: { top: 4, left: 5, bottom: 4, right: 5 },
};

export const calculateHeader = {
  headerCALIBRATIONCERTIFICATExpostion:
    headerConfig.headerCALIBRATIONCERTIFICATE
      ? headerConfig.lettterImgHeight +
        headerConfig.headerCALIBRATIONCERTIFICATEgap
      : headerConfig.lettterImgHeight +
        headerConfig.headerCALIBRATIONCERTIFICATEwithoutgap,
  headerCALIBRATIONCERTIFICATExpostionafter:
    headerConfig.lettterImgHeight +
    headerConfig.headerCALIBRATIONCERTIFICATEgap +
    10,
};

export const totalHeaderHeightWithCalibrationLableorNot = {
  total: headerConfig.headerCALIBRATIONCERTIFICATE
    ? headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 20
    : headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 7,
};

export const footerConfig = {
  footerCALIBRATIONCERTIFICATE: false,
  footerimgRemove:false,
  footerlinewidth: 0,
  footerimgHeight: 5,
  footertableHeight: 50,
  footerandbodygap: 37,
  endcertificateandimggap: 5,
  stampui: false,
  footerCompanyNameFirstRow: "Labfix",
};
let pageHeight = 841;
export const certificateBorderLayout = {
  x: 15,
  y: totalHeaderHeightWithCalibrationLableorNot.total - 5,
  width: 565,
  height:
    pageHeight -
    footerConfig.footerimgHeight -
    footerConfig.footertableHeight -
    footerConfig.footerandbodygap -
    totalHeaderHeightWithCalibrationLableorNot.total +
    6,
  // height: 570
};

export const tableConfiguration = {
  margins: { top: 0, right: 25, bottom: 0, left: 25 },
};

export const tableConfigurationheaderandfooter = {
  margins: { top: 0, right: 15, bottom: 0, left: 15 },
};

export const fontFamilyConfig = {
  useFont: "Aptos",
  bold: "bold",
  normal: "normal",
};

export const waterMarkImgConfig = {
  logoWidth: 150,
  logoHeight: 120,
};

export const environmentTableConfig = {
  spacebetweenHeadingandTable: 5,
  innerBorder: 0,
  cellPadding: { top: 2, left: 5, bottom: 2, right: 5 },
};

export const loadAndAddFont = async (
  pdfDoc,
  fontUrl,
  fontNameTTF,
  fontName,
  fontStyle
) => {
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const chunkSize = 8192; // Process in chunks for large buffers
    for (let i = 0; i < bytes.length; i += chunkSize) {
      binary += String.fromCharCode(...bytes.subarray(i, i + chunkSize));
    }
    return btoa(binary);
  };

  const fontResponse = await fetch(fontUrl);
  const fontBuffer = await fontResponse.arrayBuffer();
  const fontBase64 = arrayBufferToBase64(fontBuffer);

  pdfDoc.addFileToVFS(`${fontNameTTF}.ttf`, fontBase64);
  pdfDoc.addFont(`${fontNameTTF}.ttf`, fontName, fontStyle);
};

export const customFontFamily = {
  bold: "bold",
  normal: "normal",
  boldFont: "AptosBold",
  normalFont: "AptosNormal",
};

export const digitallySignConfig = {
  digitallySignONorOFF: false,
  signatureTextContnet: [
    `REVA PHOENIX LABS AND CONSULTANTS`,
    `PRIVATE LIMITED`,
    `Date:  ${new Date()
      .toLocaleString("en-GB", { timeZone: "Asia/Kolkata" })
      .replace(",", "")} +05:30`,
    `Reason: Calibration Certificate`,
    `Location: Chennai`,
  ],
};



export let certificateEndSection = [
  {
    type: "header",
    align: "center",
    content: "VALIDITY/CONDITIONS OF CALIBRATION",
  },
  {
    type: "table",
    index: true,
    content: [
      "The values  of this certificate and its results were correct at the time of calibration. Subsequent accuracy will depend on various factors such as handling, frequency of use, operating temperature and operation other than specified by the manufacturer.",
      "It is recommended that re-calibration be undertaken at an interval that will ensure that the instrument remains within the desired limits. The results of the measurements are traceable to the national or international measurement standards.",
      "The uncertainty limits quoted refer to the measured values only, with no account being taken of the instruments ability to maintain its calibration.",
      "In the event of any erroneous calibration / certification of work performed for the applicant, any legal liability arising shall be limited to the cost of re-calibration and / or certification. LABFIX will not be held responsible for any claims, consequential damage or loss.",
      "The calibration status expires if the seal is broken, or the instrument is adjusted or tampered with.",
      "This certificate may not be reproduced except in full without the written approval of LABFIX."
    ],
  },]

// Define a function to generate the textList array
export const certificateFirstSectionHeading = (
  disciplineName,
  certificateDetails
) => {
  return [
    { text: disciplineName || "", fontSize: 17 },
    { text: "Calibration Certificate", fontSize: 17 },
    { text: "For", fontSize: 17 },
    {
      text: certificateDetails?.requestedname
        ? certificateDetails?.requestedname
        : certificateDetails?.instrumentName || "",
      fontSize: 17,
    },
  ];
};

export let certificatefirstSection = [];

// Exporting MasterEQPDetails
export const MasterEQPDetails = (standards) => ({
  heading: "DETAILS OF STANDARD USED FOR CALIBRATION",
  headingFontSize: 9,
  headingBottomSpace: 0,
  headingTopSpace: 0,
  headingAlign: "center",
  spacebetweenHeadingandTable: 5,
  cellPadding: { top: 4, left: 5, bottom: 4, right: 5 },
  tableTextfontSize: 9,
  tableValueAlign: "center",
  keys: [
    // "Sl. No.",
    "Instrument Name",
    "Serial No",
    "Certificate No",
    // "Calibrated By",
    "Expiry Date",
  ],
  values: standards.map((item, index) => [
    // index + 1,
    item.standardName,
    item.serialNo,
    item.certificateNumber,
    // item.traceability,
    // `${item.certificateNumber} Valid till ${DateisValid(item.validUpto)}`,
    DateisValid(item.validUpto),
  ]),
});

export const UUCDetails = (certificateDetails) => {
  const getExtraColumn = (key) => {
    const value = certificateDetails?.extraColumnsParse?.[key];
    return value ? { key, value } : null; // Only return key-value pair if there's a value
  };

  const standardFields = [
    {
      key: "Name of Instrument",
      value:
        certificateDetails?.requestedname || certificateDetails?.instrumentName,
    },
    // { key: "SRF No", value: certificateDetails?.certificatesserviceReqNumber },
    { key: "Identification No", value: certificateDetails?.DUCID },
    { key: "Make", value: certificateDetails?.make },
    { key: "Serial No", value: certificateDetails?.serialNumber },
    { key: "Model", value: certificateDetails?.model },
    { key: "Accuracy", value: accuracyFormat(certificateDetails?.accuracy) },
    { key: "Range", value: parseRange(certificateDetails?.ranges) },
    { key: "Location", value: certificateDetails?.locationOfInstrument },
    {
      key: "Resolution/Least Count",
      value: parseRange(certificateDetails?.lc),
    },
    { key: "Condition of Instrument", value: certificateDetails?.ConOfDuc },
    {
      key: "Calibration Location",
      value: certificateDetails?.location == 1 ? "In House" : "Onsite",
    },
    { key: "Department", value: certificateDetails?.department },
    {
      key: "Calibration Procedure",
      value: certificateDetails?.calProcRefNo
        ? certificateDetails?.calProcRefNo
        : certificateDetails?.calibrationProcedureNo,
    },
    // { key: "Operating Range", value: parseRange(certificateDetails?.operatingRange) },
    // {
    //   key: "Standard Reference",
    //   value: certificateDetails?.isaStandard
    //     ? certificateDetails?.isaStandard
    //     : certificateDetails?.referenceStandards,
    // },
  ];

  // Extra fields
  const extraFields = ["Material"];

  // Add extra fields only if they have a value
  const extraPanelFields = extraFields
    .map(getExtraColumn)
    .filter((item) => item !== null);
  // Filter standard fields to include only those with values
  const filteredStandardFields = standardFields.filter((field) => field.value);
  // Combine filtered standard fields with extra fields (no special ordering)
  const allFields = [...filteredStandardFields, ...extraPanelFields];
  // Create rows from the fields, pairing key-value pairs
  const rows = [];
  for (let i = 0; i < allFields.length; i += 2) {
    rows.push([allFields[i], allFields[i + 1] || null]); // Add extra field as null if there's no pair
  }

  return {
    heading: "DETAILS OF UNIT UNDER CALIBRATION (UUC)",
    headingFontSize: 9,
    headingBottomSpace: 0,
    headingTopSpace: 0,
    headingAlign: "center",
    spacebetweenHeadingandTable: 5,
    innerBorder: 0,
    cellPadding: { top: 2, left: 5, bottom: 2, right: 5 },
    tableTextfontSize: 9,
    middleColumn: true,
    rows,
  };
};

export const NameAndAddressDetails = (certificateDetails) => {
  return {
    leftPanel: {
      keys: ["Customer Name & Address"],
      values: [
        `${certificateDetails?.companyName || ""}\n${
          certificateDetails?.newAddressArray?.address || ""
        }`,
      ],
    },
    rightPanel: {
      keys: ["Date Calibration", "Due Date", "Date of Issue"],
      values: [
        // DateisValid(certificateDetails?.receiptDate),
        DateisValid(certificateDetails?.calibrationDate),
        certificateDetails?.calFrequency == "Not Required"
          ? "-"
          : DateisValid(certificateDetails?.nextDueDate),
        certificateDetails?.dateOfIssue
          ? DateisValid(certificateDetails?.dateOfIssue)
          : DateisValid(certificateDetails?.approvedDate),
      ],
    },
    cellPadding: { top: 3, left: 5, bottom: 3, right: 5 },
    tableTextfontSize: 9,
  };
};

export const performanceClaibrationDetails = (certificateDetails) => ({
  leftPanel: {
    keys: ["Date of Performance of Calibration"],
    values: [DateisValid(certificateDetails?.calibrationDate)],
  },
  rightPanel: {
    keys: ["Date of Issue", "Recommended Due Date"],
    values: [
      certificateDetails?.dateOfIssue
        ? DateisValid(certificateDetails?.dateOfIssue)
        : DateisValid(certificateDetails?.approvedDate),
        certificateDetails?.calFrequency == "Not Required" ? "-" : DateisValid(certificateDetails?.nextDueDate),
    ],
  },
});

export const dynamicHeader = (certificateDetailsConfig, calType) => {
  const headerKeysAndValues = calType != 1
  ? [{ keys: "Certificate Number", values: certificateDetailsConfig?.certificateNumber }]
  : [
      // { keys: "ULR Number", values: certificateDetailsConfig?.ULRNo?.replace("\n", "") },
      { keys: "Certificate Number", values: certificateDetailsConfig?.certificateNumber },
    ];

    
    const leftAndRightKeyvalue = headerKeysAndValues.filter((field) => field.values);
    const rows = [];
    for (let i = 0; i < leftAndRightKeyvalue.length; i += 2) {
      rows.push([leftAndRightKeyvalue[i], leftAndRightKeyvalue[i + 1] || null]);
    }

  return {
    mergeKeyValue:true,
    headerKeysAndValues,
    firstcolheadersecondcolvalue:false,   
    rows
  };
};


