import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

const staticHeader = (doc, finalY, instrumentCount, fromDate, toDate) => {
  // Define the static header content
  const headerData = [
    ["Clients Name", ":", `${instrumentCount?.companyNamesArray?.join(", ")}`],
    [
      "Date Of Calibration",
      ":",
      `From ${fromDate ? moment(fromDate).format("DD-MM-YYYY") : null} To ${
        toDate ? moment(toDate).format("DD-MM-YYYY") : null
      }`,
    ],
    ...(instrumentCount?.instrumentCountsArray?.map((instrument) => [
      `${instrument.InstrumentId}`,
      ":",
      `${instrument.count}`,
    ]) || []),
  ];

  // Use autoTable to render the header
  doc.autoTable({
    body: headerData,
    startY: finalY + 1,
    margin: { top: 5, left: 10, right: 10 },
    theme: "plain",
    styles: {
      fontSize: 9,
      fontStyle: "normal",
      cellPadding: 1,
      textColor: [0, 0, 0],
    },
    columnStyles: {
      0: { fontStyle: "bold", cellWidth: 70 },
      1: { cellWidth: 5 },
      2: { cellWidth: 115 },
    },
  });

  // Optionally return the final Y position to adjust for further content
  return doc.lastAutoTable.finalY;
};

export const commanTable = (finalData, columns) => {
  // Check if any column has id "thicknessTest"
  const hasThicknessTest = columns.some(
    (column) => column.id === "thicknessTest"
  );

  // Generate the first row of headers
  const firstRow = columns.map((column) => {
    if (column.id === "thicknessTest") {
      return {
        content: "Thickness Observed in mm",
        colSpan: 2,
      };
    } else if (column.id === "id" && hasThicknessTest) {
      return {
        content: column.label,
        rowSpan: 2,
      };
    } else {
      return {
        content: column.label,
        rowSpan: hasThicknessTest ? 2 : 1,
        styles: column.id != "id" ? { minCellWidth: 20 } : {},
      };
    }
  });

  // Generate the second row of headers for "Shell" and "Dish"
  const secondRow = hasThicknessTest
    ? [
        { content: "Shell", styles: { minCellWidth: 25 } },
        { content: "Dish", styles: { minCellWidth: 25 } },
      ]
    : [];

  const tableHeader = [firstRow];
  if (secondRow.length > 0) {
    tableHeader.push(secondRow);
  }

  // Generate tableData
  const tableData = finalData
    ?.sort((a, b) => a.id - b.id)
    ?.map((row, index) => {
      return columns.map((column, columnIndex) => {
        let value;

        if (
          column.id === "typeIdentificationNumber" ||
          column.id === "distinctiveNumber" ||
          column.id === "ultrasonicTest"
        ) {
          value = row[column.id] || "";
        } else {
          if (
            column.id === "certificateValidity" ||
            column.id === "inspectionDate" ||
            column.id === "hydraulicTestDate" ||
            column.id === "utrasonicNextDueDateCalculate" ||
            column.id === "hydraulicNextDueDateCalculate" ||
            column.id === "utrasonicTestDate"
          ) {
            const newDate = moment(row[column.id]).format("DD-MM-YYYY");
            value = row[column.id] ? newDate : null;
          } else {
            value = row[column.id];
          }
          if (column.id === "id") {
            value = index + 1;
          }
        }

        if (typeof value === "string") {
          value = `${value.replace(/\s+/g, " ").replace(/\\n/g, "\n").trim()}`;
        }

        return value || "";
      });
    });

  return { tableHeader, tableData };
};

const drawText = (
  doc,
  text,
  position,
  y,
  { size = 12, fontStyle = "normal", fontFamily = "helvetica" } = {}
) => {
  doc.setFont(fontFamily, fontStyle);
  doc.setFontSize(size);

  const lines = text.split("\n"); // Split text by newline
  let updatedY = y;

  lines.forEach((line) => {
    // Calculate text alignment based on position
    let x = 10;
    if (position === "center") {
      x = doc.internal.pageSize.getWidth() / 2;
      doc.text(line, x, updatedY, { align: "center" });
    } else if (position === "right") {
      x = doc.internal.pageSize.getWidth() - 10;
      doc.text(line, x, updatedY, { align: "right" });
    } else if (position?.includes(",")) {
      const [alignment, xPosition] = position?.split(",");
      x = doc.internal.pageSize.getWidth() - xPosition;
      doc.text(line, x, updatedY, { align: alignment });
    } else {
      doc.text(line, x, updatedY, { align: "left" });
    }

    // Update Y-coordinate for the next line
    const textHeight = doc.getTextDimensions(line).h;
    updatedY += textHeight;
  });

  // Return the updated Y-coordinate with padding
  return updatedY;
};

export const generatePDF1 = async ({
  calData,
  printColumns,
  instrumentCount,
  fromDate,
  toDate,
}) => {
  const doc = new jsPDF({
    orientation: "p",
    compress: true,
  });

  const totalWidth = doc.internal.pageSize.getWidth();
  const totalHeight = doc.internal.pageSize.getHeight();
  const margin = 5;
  const borderMagin = 3;
  const contentWidth = totalWidth - margin * 2;
  const widthFor0And2 = 7;
  const remainingWidth = contentWidth - 2 * widthFor0And2;
  let latestY = margin + 1;

  const drawMarginRect = (doc) => {
    doc.setDrawColor(0); // Black border
    doc.setLineWidth(0.5);
    doc.rect(
      borderMagin,
      borderMagin,
      totalWidth - borderMagin * 2,
      totalHeight - borderMagin * 2
    );
  };

  latestY = drawText(doc, "INDEX", "center", latestY + 10, {
    size: 14,
    fontStyle: "bold",
    fontFamily: "times",
  });

  // Add header and footer
  latestY = await staticHeader(doc, latestY, instrumentCount, fromDate, toDate);

  // Extract table header and data
  const { tableHeader, tableData } = commanTable(calData, printColumns);

  doc.autoTable({
    head: tableHeader,
    body: tableData,
    startY: latestY + 5,
    margin: { top: 5, left: margin + 2, right: margin + 2, bottom: 5 },
    theme: "grid",
    styles: {
      fontSize: 8,
      cellPadding: 2,
      textColor: [0, 0, 0],
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
    },
    headStyles: {
      textColor: [0, 0, 0],
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: false,
    },
    didDrawPage: () => {
      drawMarginRect(doc);
    },
  });

  // Save the PDF
  doc.save("SummaryReport.pdf");
};
